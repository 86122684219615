import { useBreakpoints } from '@vueuse/core';

// customized tailwind breakpoints
export default useBreakpoints({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  '2xl': 1536,
});
